import decamelizeKeysDeep from "decamelize-keys-deep";
import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    inputWithCollectionIds,
    sourceToUniqueId
} from "../metadata/inputMapper";
import {damsSearch} from "../app/damsFetch";
import {apiCoreObjectToDAMSInput} from "../utility";
import {clientLog} from "../clientLog";


/**
 * Excludes data related to specified KulturNav datasets from the "data" object.
 * @param data
 * @returns {*}
 */
export const sanitizeKulturNavData = data => {
    const excludeDatasets = window._env_.REACT_APP_SUBJECTS_EXCLUDE_DATASETS;
    if (!excludeDatasets || excludeDatasets?.length === 0) {
        clientLog('warn',
            'subjects exclude datasets arrays is undefined or empty',
            'subjectssearchhandler');
        return data;
    }

    return data.filter(d => {
        return d.properties['entity.dataset'].find(ed => {
            return !excludeDatasets.includes(ed.value);
        });
    });
};

/**
 * Method used to search for a subject, both in KulturNav and in the local DAMS database.
 * @param params    str     The name of the subject to search for
 * @param museums   Array   An array of museum IDs to limit the results to.
 * @param locale    str     The current locale
 * @param callback  function    The callback run when the search has completed.
 */
export const subjectsSearchHandler = (params, museums, locale, callback) => {

    const searchParams = new URLSearchParams(
        decamelizeKeysDeep({
            q: `_title_:${params}`,
            fl: "title,id",
            sort: "title asc",
            expand: true,
            documentType: `("subject")`,
            museums: museums.join(","),
        })
    );

    const removeDeprecatedKey = 'superconcept.status:853a2d84-5b2b-11e2-bcfd-0800200c9a66';

    Promise.all([
        kulturnavApiCoreFetch(
            `entityType:Concept,nativeCompoundName:${params},${removeDeprecatedKey}?displayValues=true&properties=entity.dataset,entity.fullCaption&lang=${locale}`
        )
            .then((json) => {
                const entities = sanitizeKulturNavData(json.entities);
                return entities.map(j => apiCoreObjectToDAMSInput(j));
            })
            .catch((error) => {
                clientLog('error', error, 'subjectsSearchHandler');
                return Promise.resolve([]);
            }),

        damsSearch(searchParams).then((json) =>
            Promise.resolve(inputWithCollectionIds(json['models']))
        ),
    ]).then(([kulturnavResponse, damsResponse]) => {
        const damsLookup = sourceToUniqueId(damsResponse);
        const kulturnavMapped = concatWithExisting(kulturnavResponse, damsLookup);
        callback([
            ...kulturnavMapped,
            ...excludeThoseAlreadyListedAsKulturnav(damsResponse, kulturnavMapped),
        ]);
    });
};
