import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../clientLog";

export class CreateMissingDocRefs {

    /**
     * Create an instance of CreateMissingDocRefs.
     *
     * @param {object[]} museumsWithIds - List of objects with museumId and collectionId
     * @param {object[]} models - List of objects with documents to check for missing references
     * @param {string[]} fieldNames - List of field names to check
     * @constructs CreateMissingDocRefs
     */
    constructor(museumsWithIds, models, fieldNames) {
        this._museumsWithIds = museumsWithIds;
        this._models = models;
        this._fieldNames = fieldNames;
        this._createdRefs = {
            persons: [],
            places: [],
            subjects: []
        };
    }

    /**
     * Iterate over the models and add missing references.
     * @returns {Promise.<object[]>} Array of objects with documents and added references.
     */
    async create() {
        let output = [];
        for (let i = 0, max = this._models.length; i < max; i++) {
            output.push(await this._addMissingRefs(this._models[i]));
        }
        return output;
    };

    /**
     * Add missing references to the given model.
     * @param {object} model - Object with content to check for missing references.
     * @returns {Promise.<object>} Model with added references.
     * @private
     */
    async _addMissingRefs(model) {
        const fieldNames = this._fieldNames;

        function findDifferingCollectionIds(mainCollectionId) {
            const obj = {...model};
            let d = [];
            for (let i = 0, max = fieldNames.length; i < max; i++) {
                const key = fieldNames[i];

                if (obj.content[key] !== null
                    && typeof (obj.content[key]) !== 'undefined'
                    && ['producer', 'person', 'places', 'subjects'].includes(key)) {
                    // Find obj.content[key] items where the collectionId is
                    // different from mainCollectionId and add them to d.
                    d = [...d, ...obj.content[key]
                        .filter(contentItem => typeof (contentItem.collectionId) === 'undefined'
                            || contentItem.collectionId !== mainCollectionId)];
                }
            }
            return d;
        }

        const {collectionId} = model;
        const differingItems = findDifferingCollectionIds(collectionId);
        if (differingItems.length > 0) {
            for (let i = 0, max = fieldNames.length; i < max; i++) {
                const key = fieldNames[i];
                for (let j = 0, max = differingItems.length; j < max; j++) {
                    await this._appendMissingRef(key, model, differingItems[j]);
                }
            }
        }
        return model;
    };

    /**
     * Replace missing references in the given model, with a new reference document if it doesn't already exist.
     * @param {string} key - Field name in the model, that contains the references.
     * @param {object} model - Model with content to check for missing references.
     * @param {object} missingRef - Object with missing reference.
     * @returns {Promise.<object>} Model with added references.
     * @private
     */
    async _appendMissingRef(key, model, missingRef) {
        const {collectionId} = model;

        // Does the missingRef exist among the created references?
        let foundRef;
        if (['producer', 'person'].includes(key)) {
            foundRef = this._createdRefs['persons']
                .find(r => r.reference.title === missingRef.reference.title && r.collection_id === collectionId);
        } else if (['places', 'subjects'].includes(key)) {
            foundRef = this._createdRefs[key].find(r => r.reference.title === missingRef.reference.title);
        }

        if (foundRef) {
            const ix = model.content[key].findIndex(p => p.reference.title === missingRef.reference.title);

            // There might be false positives among the found-refs,
            // where the collectionId is not the same as the current collectionId.
            if (foundRef.collectionId === collectionId) {
                model.content[key][ix] = foundRef;
                return model;
            }
        }

        if (key === 'persons' || key === 'producer') {
            return await this._appendMissingPersonsRef(collectionId, missingRef, model, key);
        } else if (key === 'places') {
            return await this._appendMissingPlacesRef(collectionId, missingRef, model, key);
        }
    };

    /**
     * Add a missing reference to the given model, if it doesn't already exist.
     * @param {number} collectionId - The id of the collection.
     * @param {object} missingRef - Object with missing reference.
     * @param {object} model - Model with content to check for missing references.
     * @param {string} key - Field name in the model, that contains the references.
     * @returns {Promise.<object>} Model with added references.
     * @private
     */
    async _appendMissingPlacesRef(collectionId, missingRef, model, key) {
        let createdRef = await this._createPlaceRef(collectionId, missingRef);
        if (createdRef === null) {
            return model;
        }

        const modelRef = this._convertToPlaceModelRef(createdRef);
        this._createdRefs[key].push(modelRef);

        // Replace the place "template" object in the model, with the created object:
        // NOTE: filter added in order to remove already added  "place objects", which has a different structure.
        const ix = model.content[key]
            .filter(i => i.reference.content)
            .findIndex(p => p.reference.content.name === createdRef['title']);
        if (ix === -1) {
            clientLog('warning',
                'Could not find place or subject with title: ' + createdRef['title'],
                'createmissingdocref');
            return model;
        }
        model.content[key][ix] = modelRef;
        return model;
    }

    /**
     * If the missingRef does not exist in the db, create a new reference and
     * append it to the model.
     *
     * @param {number} collectionId - The id of the collection.
     * @param {object} missingRef - The missing reference object.
     * @param {object} model - The model to modify.
     * @param {string} key - The field name in the model to modify.
     *
     * @returns {object} The modified model.
     */
    async _appendMissingPersonsRef(collectionId, missingRef, model, key) {
        const createdRef = await this._createPersonRef(collectionId, missingRef);
        if (createdRef === null) {
            return model;
        }

        const modelRef = this._convertToPersonModelRef(createdRef);
        this._createdRefs[(key === 'producer' || key === 'persons') ? 'persons' : key].push(modelRef);

        // Replace the person "template" object in the model, with the created object:
        const ix = model.content[key].findIndex(p => p.reference.title === createdRef.title);
        if (ix === -1) {
            clientLog('warning', 'Could not find person with title: ' + createdRef.title, 'createmissingdocref');
            return model;
        }
        model.content[key][ix] = modelRef;
        return model;
    }

    /**
     * Converts a created place reference document to the reference object used within the model.
     *
     * The returned object:
     *  {
     *   "reference": {
     *     "id": 28,
     *     "title": "Albriktsen, Ingvald Johan (1884 - 1975)",
     *     "locale": "no",
     *     "source": "Fotografregisteret (Norge) (Preus museum)",
     *     "sourceId": "cd450e30-3ad8-40b7-8a1e-2a871097d1cb",
     *     "uniqueId": "fb608459-7cae-43bc-9488-71ac23e40272",
     *     "createdAt": null,
     *     "updatedAt": null,
     *     "schema_id": null,
     *     "referenceType": "person",
     *     "status": "published"
     *   },
     *   "collection_id": 2,
     *   "referenceType": "person"
     *  }
     *
     * @param {object} createdRef - The created KulturNav reference document
     * @returns {object} The reference object used within the model.
     */
    _convertToPlaceModelRef(createdRef) {
        const {
            id,
            uniqueId,
            collectionId,
            source,
            sourceId,
            title,
            createdAt,
            updatedAt,
            documentType,
            status
        } = createdRef;

        return {
            reference: {
                id: id,
                title: title,
                locale: 'no',
                source: source || '',
                sourceId: sourceId || '',
                uniqueId: uniqueId || '',
                createdAt: createdAt,
                updatedAt: updatedAt,
                schemaId: null,
                referenceType: documentType,
                status: status
            },
            referenceType: 'place',
            collectionId: collectionId
        };
    }

    /**
     * Convert a created person reference document to the reference object used within the model.
     *
     * The returned object:
     *  {
     *   "reference": {
     *     "id": 28,
     *     "title": "Albriktsen, Ingvald Johan (1884 - 1975)",
     *     "locale": "no",
     *     "source": "Fotografregisteret (Norge) (Preus museum)",
     *     "status": "published",
     *     "schema_id": null,
     *     "source_id": "cd450e30-3ad8-40b7-8a1e-2a871097d1cb",
     *     "unique_id": "fb608459-7cae-43bc-9488-71ac23e40272",
     *     "created_at": null,
     *     "updated_at": null,
     *     "document_type": null
     *   },
     *   "collection_id": 2,
     *   "reference_type": "person"
     *  }
     *
     * @param {object} createdRef - The created KulturNav reference document
     * @returns {object} The reference object used within the model.
     */
    _convertToPersonModelRef(createdRef) {
        return {
            reference: {
                id: createdRef.id,
                title: createdRef.title,
                locale: 'no',
                source: '',
                sourceId: '',
                uniqueId: createdRef.uniqueId,
                createdAt: createdRef.createdAt,
                updatedAt: createdRef.updatedAt,
            },
            collection_id: createdRef.collectionId,
            referenceType: createdRef.documentType
        };
    };

    /**
     * Create a place reference document in the local database, if it does not exist.
     *
     * @param {int} collectionId - The ID of the collection to save the reference in.
     * @param {object} item - Contains the reference object to be saved.
     * @returns {Promise.<object|null>} The created reference document, or null if it already exists.
     *
     * The `item` object is expected to have the following structure:
     *  {
     *   "referenceType": "place",
     *   "collectionId": 3,
     *   "reference": {
     *     "id": 79,
     *     "uniqueId": "cf65e811-a095-4b4b-8c2c-c90cd875fd68",
     *     "sourceId": "b4ae0abe-e630-4efe-a288-0d1c2a86daec",
     *     "source": "Norske stedsnavn",
     *     "title": "Oslo (By) < Oslo (Kommune)",
     *     "nameAndTitle": "",
     *     "content": {
     *       "name": "Oslo",
     *       "nameAndTitle": "Oslo (By) < Oslo (Kommune)",
     *       "yearOfBirth": "",
     *       "yearOfDeath": ""
     *     },
     *     "status": "published",
     *     "locale": "no"
     *   }
     *  }
     *
     * The returned object is the created reference document, or null if it already exists.
     */
    async _createPlaceRef(collectionId, item) {
        const {reference} = item
        const {source, sourceId, status} = reference;

        // When fetching the place document from the model, the reference.content may be missing:
        let content;
        if (!reference.content) {
            content = {
                name: reference.title,
                nameAndTitle: reference.nameAndTitle,
                yearOfBirth: "",
                yearOfDeath: ""
            }
        } else {
            content = reference.content;
        }

        const {name, nameAndTitle, yearOfBirth, yearOfDeath} = content;
        let placeDocument = {
            collectionId: collectionId,
            title: name,
            source: source,
            sourceId: sourceId,
            status: status,
            locale: "no",
            documentType: "place",
            schemaId: 1,
            content: {
                name: name,
                nameAndTitle: nameAndTitle,
                yearOfBirth: yearOfBirth,
                yearOfDeath: yearOfDeath
            }
        };
        return await this._createRefInDatabaseIfNotExists('place', placeDocument);
    }

    /**
     * Create a person reference document in the local database, if it does not exist.
     *
     * @param {int} collectionId - The ID of the collection to save the reference in.
     * @param {object} item - Contains the reference object to be saved.
     * @returns {Promise.<object|null>} The created reference document, or null if it already exists.
     *
     * The `item` object is expected to have the following structure:
     *  {
     *   "referenceType": "person",
     *   "collectionId": 3,
     *   "reference": {
     *     "id": 79,
     *     "uniqueId": "cf65e811-a095-4b4b-8c2c-c90cd875fd68",
     *     "sourceId": "b4ae0abe-e630-4efe-a288-0d1c2a86daec",
     *     "source": "Norske stedsnavn",
     *     "title": "Oslo (By) < Oslo (Kommune)",
     *     "nameAndTitle": "",
     *     "content": {
     *       "name": "Oslo",
     *       "nameAndTitle": "Oslo (By) < Oslo (Kommune)",
     *       "yearOfBirth": "",
     *       "yearOfDeath": ""
     *     },
     *     "status": "published",
     *     "locale": "no"
     *   },
     *   "source": "Norske stedsnavn",
     *   "sourceId": "b4ae0abe-e630-4efe-a288-0d1c2a86daec"
     *  }
     *
     * The returned object is the created reference document, or null if it already exists.
     */
    async _createPersonRef(collectionId, item) {
        let personDocument;
        const {reference, source, sourceId} = item;

        if (typeof (reference.content) === 'undefined') {
            const content = {
                yearOfBirth: '',
                yearOfDeath: '',
                name: reference.title,
                nameAndTitle: reference.title
            };
            personDocument = {
                collectionId: collectionId,
                schemaId: 1,
                locale: "no",
                _action: "put",
                title: reference.title,
                status: "published",
                source: source,
                sourceId: sourceId,
                documentType: "person",
                content
            };
        } else {
            // Create a new person document.
            const {content} = reference;
            const {yearOfBirth, yearOfDeath, name, nameAndTitle} = content;
            personDocument = {
                collectionId: collectionId,
                schemaId: 1,
                locale: "no",
                _action: "put",
                title: name,
                status: "published",
                source: item.reference?.source || '',
                sourceId: item.reference?.sourceId || '',
                documentType: "person",
                content: {
                    yearOfBirth: yearOfBirth,
                    yearOfDeath: yearOfDeath,
                    name: name,
                    nameAndTitle: nameAndTitle
                },
            };
        }
        return this._createRefInDatabaseIfNotExists('person', personDocument);
    };

    /**
     * Create a reference document in the local database, if it does not exist.
     *
     * @param {string} refType - The type of reference document to create.
     * @param {object} document - The document object to be saved.
     * @returns {Promise.<object|null>} The created reference document, or null if it already exists.
     * @private
     */
    async _createRefInDatabaseIfNotExists(refType, document) {
        try {
            const {collectionId, title, status} = document;
            if (!collectionId || !title || !status) {
                return null;
            }
            const exists = await this._refExistsInDb(collectionId, 'place', title, status);
            if (exists) {
                return null;
            }
            return await damsFetch('/documents', {
                method: 'POST',
                body: JSON.stringify(decamelizeKeysDeep(document))
            });
        } catch (error) {
            clientLog('error', error.toString(), 'createmissingdocref');
            return null;
        }
    };

    /**
     * Check if a document with the given title, status, and collection exists in the
     * local DAMS database.
     *
     * @param {number} collectionId - The collection ID of the document.
     * @param {string} documentType - The document type of the document.
     * @param {string} title - The title of the document to check for.
     * @param {string} status - The status of the document to check for.
     * @returns {Promise.<boolean>} true if the document exists, false otherwise.
     * @private
     */
    async _refExistsInDb(collectionId, documentType, title, status) {
        try {
            // Check if an object exists with the same title, related to the current collection.
            const museumId = this._museumsWithIds.filter(m => m.collectionId === collectionId).map(m => m.museumId);
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: `_title_:"${title}"`,
                    fl: "title,id, collectionId",
                    sort: "title asc",
                    expand: true,
                    documentType: `("${documentType}")`,
                    museums: museumId,
                    status: status
                })
            );
            const result = await damsFetch(`/search?${searchParams.toString()}`);
            return !!(result && result?.count > 0 && result?.models.length > 0);
        } catch (error) {
            clientLog('error', error.toString(), 'createmissingdocref');
            return false;
        }
    };
}