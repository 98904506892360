import {format} from "../app/dateFormat";
import {DamsFileIcon} from "../files/DamsFileIcon";
import {getContentSelector} from "../project/projectUtility";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

/**
 * Returns a list of table columns for use in the search interface.
 *
 * Each column is an object with the following properties:
 * - id: The id of the column.
 * - disablePadding: A boolean indicating whether the column should have padding.
 * - checked: A boolean indicating whether the column is checked by default.
 * - getLabel: A function returning the header name to display in the table.
 * - disableSorting: A boolean indicating whether the column is sortable.
 * - getValue: A function returning the value of the cell.
 * - defaultHidden: A boolean indicating whether the column should be hidden by default.
 * - projectList: A boolean indicating whether the column should be visible in the project list.
 *
 * @returns {object[]} The list of columns.
 */
export const getTableColumns = () => [
    {
        id: "fileType",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("fileType", "Filtype"),
        disableSorting: true,
        getValue: (row) => {
            // Projects are defined as a special type of folder, not having the row.content.mediae property.
            if (!row.content.mediae) {
                return (
                    <></>
                );
            } else {
                const mimeType = row.content.mediae[0].reference.mimeType;
                return (
                    <DamsFileIcon
                        mimeType={mimeType}
                        size={32}
                        filename={row.content.mediae[0].reference.title}
                    />
                );
            }
        },
        defaultHidden: false,
        projectList: false
    },
    {
        id: 'project_title',
        disablePadding: false,
        checked: false,
        getLabel: (t) => t('projectName', 'Prosjektnavn'),
        disableSorting: false,
        getValue: (row) => {
            if (!Array.isArray(row.folders) && row.folders?.length === 0) {
                return '';
            }
            return row.folders?.find(f => f.type === 'ProjectArcheology' || f.type === 'ProjectGeneral')?.title || '';
        },
        defaultHidden: false,
        projectList: false
    },
    {
        id: "project",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t('project', 'Prosjekt'),
        disableSorting: true,
        getValue: (row) => {
            if (row.folderType === 'ProjectArcheology') {
                return 'Arkeologi';
            }
            if (row.folderType === 'ProjectGeneral') {
                return 'Generelt';
            }
        },
        defaultHidden: false,
        projectList: true
    },
    {
        id: 'project-state',
        disablePadding: true,
        checked: true,
        getLabel: (t) => t('projectState', 'Status'),
        disableSorting: true,
        getValue: (row) => {
            const selector = getContentSelector(row);
            const {content} = row;
            const state = content[selector].active
            let icon;
            if (typeof (state) === "undefined" || state) {
                icon = <LockOpenIcon/>;
            } else if (!state) {
                icon = <LockIcon/>;
            }
            return icon;
        },
        defaultHidden: false,
        projectList: true
    },
    {
        id: "title",
        disablePadding: true,
        checked: false,
        getLabel: (t) => t("title", "Tittel"),
        disableSorting: false,
        cellProps: {component: "th", scope: "row", padding: "none"},
        getValue: (row) => row.title,
        defaultHidden: false,
    },
    {
        id: "description",
        disablePadding: true,
        checked: false,
        getLabel: (t) => t("description", "Beskrivelse"),
        disableSorting: false,
        getValue: (row) => {
            if (row.content.mediae) {
                return row.description;
            } else {
                // Projects are defined as a special type of folder, not having the row.content.mediae property.
                // Instead the description is stored under:
                // model.content[archeologyprojectref | generalprojectref].description.
                return row.content[getContentSelector(row)].description;
            }
        },
        defaultHidden: true,
    },
    {
        id: "museumName",
        disablePadding: true,
        checked: true,
        getLabel: (t) => t('museumName', 'Museum'),
        disableSorting: false,
        getValue: (row) => row.museumName || '',
        defaultHidden: false,
        projectList: true
    },
    {
        id: "customIdentifier",
        disablePadding: true,
        checked: false,
        getLabel: (t) => t("customIdentifier", "Museumsnummer"),
        disableSorting: false,
        getValue: (row) => row.content.customIdentifier,
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "productionDate",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("productionDate", "Produksjonsdato"),
        disableSorting: false,
        getValue: (row) => format(row.content.productionDate, "P"),
        defaultHidden: false,
        projectList: false,
    },
    {
        id: "documentType",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("documentType", "ObjektType"),
        disableSorting: false,
        getValue: (row) => row.documentType,
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "producer.reference.title",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("producer", "Fotograf / Produsent"),
        disableSorting: true,
        getValue: (row) => {
            if (!row.content.producer) {
                return "";
            } else if (Array.isArray(row.content.producer)) {
                return row.content.producer
                    .map((producer) => producer.reference.title)
                    .join(", ");
            }
        },
        defaultHidden: false,
        projectList: false,
    },
    {
        id: "persons.reference.title",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("persons", "Personer"),
        disableSorting: true,
        getValue: (row) =>
            row.content.persons
                ? row.content.persons.map((person) => person.reference.title).join(", ")
                : "",
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "places.reference.title",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("places", "Steder"),
        disableSorting: true,
        getValue: (row) =>
            row.content.places
                ? row.content.places.map((place) => place.reference.title).join(", ")
                : "",
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "licenses.reference.title",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("licenses", "Lisenser"),
        disableSorting: true,
        getValue: (row) =>
            row.content.licenses
                ? row.content.licenses
                    .map((license) => license.reference.title)
                    .join(", ")
                : "",
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "createdByName",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("createdBy", "Opprettet av"),
        disableSorting: false,
        getValue: (row) => row.createdByName,
        defaultHidden: false,
    },
    {
        id: "createdAt",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("uplodadedAt", "Opplastet dato"),
        disableSorting: false,
        getValue: (row) => format(row.createdAt, "P"),
        defaultHidden: false,
    },
    {
        id: "updatedAt",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("modifiedAt", "Dato endret"),
        disableSorting: false,
        getValue: (row) => format(row.updatedAt, "P"),
        defaultHidden: false,
    },
    {
        id: "uniqueId",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("damsId", "DAMSID"),
        disableSorting: false,
        getValue: (row) => row.uniqueId,
        defaultHidden: true,
    },
    {
        id: "mediae.reference.fileExtension",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("fileExtension", "Filendelse"),
        disableSorting: false,
        getValue: (row) => {
            if (!row.content.mediae) {
                // Projects are defined as a special type of folder, not having the row.content.mediae property.
                return "";
            } else {
                return row.content.mediae[0].reference?.fileExtension;
            }
        },
        defaultHidden: true,
        projectList: false,
    },
    {
        id: "mediae.reference.fileSize",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("fileSize", "Filstørrelse"),
        disableSorting: false,
        getValue: (row) => {
            if (!row.content.mediae) {
                // Projects are defined as a special type of folder, not having the row.content.mediae property.
                return "";
            }
            return (
                (row.content?.mediae[0].reference.fileSize / (1024 * 1024)).toFixed(2) + " MB"
            );
        },
        align: "right",
        defaultHidden: false,
        projectList: false,
    },
    {
        id: "copyright_type",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("copyrightType", "Copyright Type"),
        disableSorting: false,
        getValue: (row, t) => {
            if (!row.content.mediae || !row.content || !row.content.copyrightType) {
                // Projects are defined as a special type of folder, not having the row.content.mediae property.
                return "";
            }

            if (row.content.copyrightType === 'copyright') {
                return t('copyrightTypeValueCopyright', 'Opphavsrett');
            } else if (row.content.copyrightType !== 'copyright' && row.content.copyrightType.length > 0) {
                return t('copyrightTypeValueNotChosen', 'Falt i det fri');
            } else {
                return '';
            }
        },
        align: "right",
        defaultHidden: false,
        projectList: false,
    },
    {
        id: "copyright_info",
        disablePadding: false,
        checked: false,
        getLabel: (t) => t("copyrightClause", "Klausul"),
        disableSorting: false,
        getValue: (row) => {
            if (!row.content.mediae || !row.content || !row.content.copyrightInfo) {
                // Projects are defined as a special type of folder, not having the row.content.mediae property.
                return "";
            }

            if (row.content.copyrightInfo.length > 0) {
                return row.content.copyrightInfo.map(ci => ci.title + ' ');
            }

            return '';
        },
        align: "right",
        defaultHidden: false,
        projectList: false,
    },
    {
        id: "subjects",
        disablePadding: true,
        checked: false,
        getLabel: (t) => t('subjects', 'Emneord'),
        disableSorting: false,
        getValue: (row) => {
            if (row.content.subjects?.length > 0) {
                if (Array.isArray(row.content.subjects)) {
                    return row.content.subjects.map(s => s.reference.title).join(', ');
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        align: 'left',
        defaultHidden: false,
        projectList: false
    }, {
        id: "reference_types",
        disablePadding: true,
        checked: false,
        getLabel: (t) => t('referenceTypes', 'Referanser'),
        disableSorting: true,
        getValue: (row) => {
            if (row.content.referenceTypes?.length > 0) {
                if (Array.isArray(row.content.referenceTypes)) {
                    return row.content.referenceTypes.map(s => s.refDescription).join(', ');
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        align: 'left',
        defaultHidden: false,
        projectList: false
    }
];
