import {SelectMuseumDialog} from "../museum/SelectMuseum/SelectMuseumDialog";
import React, {useMemo, useState} from "react";
import {useDocumentTranslation} from "../documents/documentContext";
import decamelizeKeysDeep from "decamelize-keys-deep";
import Paper from "@mui/material//Paper";
import Typography from "@mui/material/Typography";
import {Dialog, DialogActions, DialogContent, useMediaQuery} from "@mui/material";
import {damsFetch} from "../app/damsFetch";
import {getTableColumnsBase} from "../table-base/getTableColumnsBase";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * A component for restoring deleted documents.
 *
 * This component is used to allow users to restore documents that have been deleted.
 *
 * The component first prompts the user to select a museum. Once a museum has been selected,
 * the component fetches the deleted documents for that museum and displays them in a table.
 *
 * The user can then select which documents to restore, and the component will send a request to
 * the server to restore the selected documents.
 *
 * The component also displays a confirmation dialog before restoring the documents,
 * to make sure the user really wants to restore the documents.
 *
 * @returns {JSX.Element} The component.
 */
export const RestoreDocuments = () => {
    const t = useDocumentTranslation();
    const smallScreen = useMediaQuery("(max-width: 768px)");
    const queryClient = useQueryClient()

    const [showConfirm, setShowConfirm] = useState(false);
    const [restoring, setRestoring] = useState(false);

    const [sorting, setSorting] = useState([{id: 'title', desc: true}]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25
    });

    const [museum, setMuseum] = useState({
        id: null,
        name: null,
        collectionId: null
    });

    const handleSelectedMuseum = museum => {
        setMuseum(museum);
    };

    /**
     * Hides the confirmation dialog.
     *
     * This function is called when the user clicks the cancel button in the
     * confirmation dialog. It simply sets the state variable `showConfirm` to
     * false, which will hide the dialog.
     */
    const handleCancelRestore = () => {
        setShowConfirm(false);
    };

    /**
     * Handles the event when the user clicks the restore button.
     *
     * This function is called when the user clicks the restore button in the
     * table. It sets the state variable `showConfirm` to true, which will show
     * the confirmation dialog.
     *
     * The confirmation dialog will ask the user if they really want to restore
     * the selected documents. If the user clicks yes, the documents will be
     * restored.
     */
    const handleRestore = () => {
        setShowConfirm(true);
    };

    /**
     * Restores the selected documents.
     *
     * This function is called when the user clicks the confirm button in the
     * confirmation dialog. It sets the state variable `restoring` to true,
     * which will show a progress indicator.
     *
     * The selected documents are restored by making a POST request to the
     * `/documents/status/{uniqueId}/published?cascade=True` endpoint. This
     * endpoint will restore the documents and their associated files.
     *
     * After all the documents have been restored, the state variable
     * `restoring` is set to false, which will hide the progress indicator.
     * Additionally, the `queryClient` is called to fetch the query
     * `["deleted"]` again, which will update the table with the new status.
     *
     * Finally, the confirmation dialog is hidden by setting the state variable
     * `showConfirm` to false.
     *
     * @async
     */
    const restoreDocuments = async () => {
        setRestoring(true);

        const documentIds = [];
        const rows = table.getSelectedRowModel().flatRows;
        for (const row of rows) {
            documentIds.push({
                id: row.original.id,
                uniqueId: row.original.uniqueId,
                documentType: row.original.documentType
            });
        }

        for (let i = 0, max = documentIds.length; i < max; i++) {
            await damsFetch(`/documents/status/${documentIds[i].uniqueId}/published?cascade=True`, {
                method: 'POST'
            });
        }

        // Clear selection, and reload the table contents.
        setRestoring(false);
        table.resetRowSelection();
        await queryClient.invalidateQueries({queryKey: ['deleted']});
        // Close the confirm dialog.
        setShowConfirm(false);
    };

    /**
     * Helper function to generate a sort criteria string from the current
     * sorting configuration.
     *
     * The sorting configuration is given by the `sorting` state variable,
     * which is an array of objects with the properties `id` and `desc`.
     * The `id` property specifies the field to sort on, and the `desc`
     * property specifies whether to sort in descending (true) or ascending
     * (false) order.
     *
     * The function takes the first element of the `sorting` array, and
     * generates a sort criteria string based on the `id` and `desc` properties.
     * The sort criteria string is in the format `field_name [asc|desc]`.
     *
     * @return {string} The sort criteria string.
     */
    function _getSortCriteria() {
        const {id, desc} = sorting[0];
        if (id === 'createdAt') {
            return `created_at ${desc ? 'desc' : 'asc'}`
        } else if (id === 'updatedAt') {
            return `updated_at ${desc ? 'desc' : 'asc'}`
        } else if (id === 'documentType') {
            return `document_type ${desc ? 'desc' : 'asc'}`
        } else if (id === 'createdByName') {
            return `created_by_name ${desc ? 'desc' : 'asc'}`
        } else {
            return `${id} ${desc ? 'desc' : 'asc'}`;
        }
    }

    /**
     * Fetches the deleted documents from the DAMS API.
     *
     * This function makes a GET request to the `/search` endpoint with the
     * following query parameters:
     *
     * - `q`: `*` (to fetch all documents)
     * - `sort`: `title desc` (to sort the results by title in descending order)
     * - `expand`: `true` (to include the document's metadata)
     * - `start`: `0` (to start at the first row)
     * - `rows`: `100` (to fetch 100 documents at a time)
     * - `documentType`: `(StillImage OR Audio OR Video OR Misc OR Dokument OR Geodata OR Tabell OR Modell)` (to only fetch documents of the specified types)
     * - `museums`: `id` (to only fetch documents from the current museum)
     * - `fq`: `status:("trash")` (to only fetch deleted documents)
     * - `onlyMine`: `false` (to include all documents, not just the user's)
     * - `onlyWriteable`: `false` (to include all documents, not just the user's writeable documents)
     *
     * The function returns an object with two properties:
     *
     * - `deleted`: An array of the deleted documents
     * - `meta`: An object with the total row count of the deleted documents
     *
     * If the `loading` state variable is true, the function returns an empty
     * object with the total row count set to 0.
     *
     * @returns {Promise<object>}
     */
    const fetchDeletedDocuments = async () => {
        let sortCriteria = 'title asc';
        if (sorting?.length > 0) {
            sortCriteria = _getSortCriteria();
        }

        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: '*',
                sort: sortCriteria,
                expand: true,
                start: pagination.pageIndex === 0 ? 0 : pagination.pageIndex * pagination.pageSize,
                rows: pagination.pageSize,
                documentType: `(StillImage OR Audio OR Video OR Misc OR Dokument OR Geodata OR Tabell OR Modell)`,
                museums: museum['id'],
                fq: ['status:("trash")'],
                onlyMine: false,
                onlyWriteable: false
            })
        );
        const res = await damsFetch(`/search?${searchParams.toString()}`);
        return {
            deleted: res.count === 0 ? [] : res.models,
            meta: {
                totalRowCount: res.count
            }
        };
    };

    const {
        data: {
            deleted = [],
            meta
        } = {},
        isError,
        isRefetching,
        isLoading,
    } = useQuery({
        queryKey: [
            'deleted',
            pagination.pageIndex,
            pagination.pageSize,
            sorting
        ],
        queryFn: fetchDeletedDocuments,
        enabled: Boolean(museum && museum.id)
    });

    const columns = useMemo(() => {
        const baseColumns = getTableColumnsBase();
        return [
            ...baseColumns,
            {
                id: 'title',
                accessorKey: 'title',
                header: 'Tittel',
                enableSorting: true
            },
            {
                id: 'documentType',
                accessorKey: 'documentType',
                header: 'Dokumenttype',
                enableSorting: true
            },
            {
                id: 'createdAt',
                accessorKey: 'createdAt',
                header: 'Opprettet',
                enableSorting: true,
            },
            {
                id: 'updatedAt',
                accessorKey: 'updatedAt',
                header: 'Oppdatert',
                enableSorting: true
            },
            {
                id: 'createdByName',
                accessorKey: 'createdByName',
                header: 'Opprettet av',
                enableSorting: true
            }
        ]
    });

    const tableConfig = {
        columns,
        data: deleted,
        enableStickyHeader: true,
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableSelectAll: true,
        enableRowSelection: true,
        enableEditing: false,
        enableColumnActions: false,
        enablePagination: true,
        manualPagination: true,
        manualSorting: true,
        muiTablePaperProps: {
            sx: {
                'display': 'flex',
                'flexDirection': 'column',
            }
        },
        muiToolbarAlertBannerProps: isError
            ? {color: 'error', children: 'Error loading data'}
            : {sx: {display: 'none'}},
        rowCount: meta?.totalRowCount ?? 0,
        muiPaginationProps: {
            showRowsPerPage: false,
        },
        onPaginationChange: setPagination,
        onSortingChange: (newSorting) => {
            setSorting(newSorting);
        },
        initialState: {
            density: 'compact',
            columnVisibility: {id: false, collectionId: false, uniqueId: false},
            pagination: {
                pageSize: pagination.pageSize,
            }
        },
        state: {
            pagination,
            isLoading,
            sorting,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
        }
    };

    const table = useMaterialReactTable(tableConfig);

    if (Boolean(!museum || !museum?.id)) {
        return <SelectMuseumDialog t={t}
                                   dialogTitle={t('imageImportSelectMuseumDialogTitle', 'Opprette jobb')}
                                   selectedMuseumCallback={handleSelectedMuseum}
                                   projectContext={false}/>;
    } else if (Boolean(museum && museum?.id)) {
        return <>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minWidth: smallScreen ? '100vw' : '90vw',
                    maxWidth: '100vw',
                    marginBottom: smallScreen ? '0px' : '32px',
                    marginTop: smallScreen ? '0px' : '32px !important',
                    marginLeft: smallScreen ? '0px' : '16px',
                    marginRight: smallScreen ? '0px' : '16px',
                    padding: '16px'
                }}>
                <Typography variant={"h5"} gutterBottom>Gjenopprett</Typography>
                <Typography variant={"body"} gutterBottom>Valgt museum: {museum.name}</Typography>
                <Typography variant={"body"} gutterBottom>
                    Listen nedenfor gir en oversikt over dokumenter som kan
                    gjenopprettes.
                </Typography>
                <MaterialReactTable table={table}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    minWidth: '100%',
                    justifyContent: 'end',
                    marginTop: '16px'
                }}>
                    <Button variant={"contained"} color={"secondary"} onClick={handleRestore}
                            disabled={table.getSelectedRowModel().flatRows.length === 0}>Gjenopprett</Button>
                </Box>
            </Paper>
            <Dialog open={showConfirm}>
                <DialogTitle>Gjenopprett dokumenter</DialogTitle>
                <DialogContent>
                    Ønsker du å gjenopprette valgte dokumenter?
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={handleCancelRestore}>Avbryt</Button>
                    <Button variant={"contained"}
                            color={"secondary"}
                            disabled={restoring}
                            startIcon={restoring && <CircularProgress size={16} color={"secondary"}/>}
                            onClick={(async () => await restoreDocuments())}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    }
};