import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {getBrowserLocale} from "../utility";


/**
 * A React component that renders an autocomplete dropdown for selecting a
 * reference type. The component fetches the list of reference types from
 * Kulturnav API and maps the result to a list of options, each with a
 * `sourceId` and a `title` property. The `title` property is localized to the
 * current browser locale, and if the reference type does not have a caption in
 * the current locale, it will fall back to the caption in the default locale.
 *
 * The component takes a `callback` property, which is a function that is
 * called when the user selects an option from the dropdown. The function
 * takes two arguments: `guid` and `value`. `guid` is a string that identifies
 * the field that the component is associated with, and `value` is the object
 * that was selected from the dropdown. The `value` object contains the
 * `sourceId` and `title` properties.
 *
 * The component also takes a `guid` property, which is a string that identifies
 * the field that the component is associated with.
 *
 * The component also takes a `value` property, which is an object that
 * contains the currently selected reference type. The object should have a
 * `sourceId` property that is a string that identifies the reference type in
 * Kulturnav API.
 *
 * @param {{callback: (guid: string, value: object) => void, guid: string, value: object}} props
 * @return {React.Element} The rendered autocomplete dropdown.
 */
export const ReferenceTypeAutoComplete = ({callback, guid, value}) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    const locale = getBrowserLocale();

    /**
     * Fetches the list of reference types from Kulturnav API.
     *
     * The function fetches the Kulturnav dataset with the given UUID and
     * maps the result to a list of options, each with a `sourceId` and a
     * `title` property. The `title` property is localized to the current
     * browser locale, and if the reference type does not have a caption in
     * the current locale, it will fall back to the caption in the default
     * locale.
     *
     * @return {Promise} A Promise that resolves with the list of options.
     */
    const fetchData = async () => {
        const referenceTypeDataSetId = '3333a637-8d82-45df-a81a-726f8f4cdc2f';
        const url = `entity.dataset:${referenceTypeDataSetId}, entityType:Concept?properties=entity.dataset,entity.fullCaption&displayValues=true&lang=${locale}`
        return await kulturnavApiCoreFetch(url);
    };

    /**
     * Maps the result from `fetchData` to a list of options.
     *
     * Each option is an object with a `sourceId` and a `title` property.
     * The `title` property is localized to the current browser locale, and
     * if the reference type does not have a caption in the current locale,
     * it will fall back to the caption in the default locale.
     *
     * @param {array} data - The list of reference types from Kulturnav API.
     * @return {array} The list of options.
     */
    const mapToOptions = (data) => {
        return data.map(d => ({
            sourceId: d.uuid,
            title: d.properties['entity.fullCaption'][0]['value'][locale]
                || d.properties['entity.fullCaption'][0]['value']['*'],
            source: d.properties['entity.dataset'][0]['displayValue'][locale]
        }));
    };

    /**
     * Opens the autocomplete dropdown and fetches the list of reference types
     * from Kulturnav API.
     *
     * This function is called when the user clicks on the input field.
     */
    const handleOpen = () => {
        setOpen(true);
        (async () => {
            const res = await fetchData();
            setOptions(mapToOptions(res.entities));
        })();
    };

    /**
     * Closes the autocomplete dropdown and resets the list of options.
     *
     * This function is called when the user clicks away from the input field,
     * or when the user selects an option from the dropdown.
     */
    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };

    /**
     * Handles changes to the Autocomplete value.
     *
     * If the reason for the change is "selectOption", it calls the callback
     * with the selected option.
     *
     * @param {React.SyntheticEvent} event The event that triggered the change.
     * @param {object} value The selected option.
     * @param {string} reason The reason for the change. Is "selectOption" if the
     * user selected an option from the dropdown.
     */
    const handleOnChange = (event, value, reason) => {
        if (reason !== 'selectOption') {
            return;
        }
        callback(guid, value);
    };

    return <Autocomplete
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disablePortal
        options={options}
        fullWidth={true}
        autoSelect={true}
        value={Object.keys(value).includes('title') ? value : {title: ''}}
        getOptionLabel={(option) => (option.title)}
        onChange={handleOnChange}
        renderInput={(params) => <TextField {...params} label="Referansetype"/>}
    />;
};