import {DialogWrapper} from "../../app/DialogWrapper";
import Typography from "@mui/material/Typography";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuseumIcon from "@mui/icons-material/Museum";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";

/**
 * Renders a single museum dialog with the provided user museum details.
 *
 * @param {function} t - Translation function
 * @param {object} userMuseum - User's museum details
 * @param {boolean} openDialog - Flag indicating if the dialog is open
 * @param {function} closeDialogCallback - Callback function to close the dialog
 * @param {function} dialogActionsCallback - Callback function for dialog actions
 * @return {JSX.Element} The rendered single museum dialog component
 */
const SingleMuseumDialog = ({t, userMuseum, openDialog, closeDialogCallback, dialogActionsCallback}) => {
    const {incorrectConfig, name} = userMuseum;

    return <DialogWrapper
        openDialog={openDialog}
        closeHandler={closeDialogCallback}
        dialogActions={() => dialogActionsCallback(userMuseum)}>
        <Typography>
            {t('selectMuseumDialogExecute', 'Du er i ferd med å utføre en oppgave mot')}
        </Typography>
        <List>
            <ListItem>
                <ListItemButton disabled={incorrectConfig} selected={!incorrectConfig}>
                    <ListItemIcon>
                        <MuseumIcon/>
                    </ListItemIcon>
                    <ListItemText primary={name}
                                  secondary={incorrectConfig ? t('selectMuseumDialogNotAvailable', 'Feil: Når ikke lagringsløsningen') : ''}/>
                </ListItemButton>
            </ListItem>
        </List>
    </DialogWrapper>;
};

SingleMuseumDialog.propTypes = {
    t: PropTypes.func.isRequired,
    userMuseum: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    closeDialogCallback: PropTypes.func.isRequired,
    dialogActionsCallback: PropTypes.func.isRequired
};

export {SingleMuseumDialog};