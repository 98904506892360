import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {clientLog} from "../clientLog";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Renders a FormikSubmitButton component.
 *
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {ReactNode} props.children - The content of the button.
 * @param {...any} props.props - Additional properties to be spread on the button.
 * @return {JSX.Element} The rendered FormikSubmitButton component.
 */
export const FormikSubmitButton = ({disabled, children, ...props}) => {
    const {submitForm, isSubmitting, isValid, errors} = useFormikContext();
    const [submittingState, setSubmittingState] = useState(false);

    /**
     * Hook used to expose the errors, when a form has invalid values.
     */
    useEffect(() => {
        if (!errors || Object.keys(errors).length === 0) {
            return;
        }
        clientLog('error', JSON.stringify(errors), 'formiksubmitbutton');
    }, [errors]);

    useEffect(() => {
        if (isSubmitting) {
            setSubmittingState(true);
        } else {
            setSubmittingState(false);
        }
    }, [isSubmitting]);

    return (
        <>

            <LoadingButton
                sx={{marginBottom: 2, marginTop: 2, marginLeft: 1}}
                variant={"contained"}
                color={"primary"}
                onClick={async (values) => await submitForm(values)}
                startIcon={submittingState ? <CircularProgress size={16} color={"secondary"}/> : <></>}
                disabled={submittingState || !isValid || disabled}
                {...props}
            >
                {children}
            </LoadingButton>
        </>
    );
};
