import React, {lazy, Suspense, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {SearchProvider} from "../../search/SearchContext";
import {SearchLandingPage} from "../../search/SearchLandingPage";

import {SET_EXTERNAL_USER, SET_MUSEUM_USER_OR_ADMIN, useAppDispatch, useAppState} from "../AppContext";
import {FoldersProvider} from "../../folder/FoldersContext";
import {useAuthsState} from "../../auths/authsContext";
import {ProjectProvider, useProjectState} from "../../project/projectContext";
import {MyFoldersProvider} from "../../folder/MyFoldersContext";
import {DocumentProvider} from "../../documents/documentContext";
import {ProjectViewProvider} from "../../project/archeology/projectview/projectViewContext";


import * as Sentry from "@sentry/react";
import {RestoreDocuments} from "../../restore-documents/RestoreDocuments";

const RestrictedAccessInformation = lazy(() => import('.././restrictedaccess/RestrictedAccessInformation'));
const NewFilesPage = lazy(() => import('../../files/NewFilesPage'));
const FileForm = lazy(() => import('../../files/FileForm'));
const FolderByIdRoute = lazy(() => import('./FolderByIdRoute'));
const AdminImportData = lazy(() => import('./AdminImportData'));
const AdministrateMetadataMapping = lazy(() => import('./AdministrateMetadataMapping'));
const AdminReindex = lazy(() => import('./AdminReIndex'));
const DAMSStats = lazy(() => import('../../admin/DAMSStats'));
const RegenerateMetadata = lazy(() => import('../../admin/RegenerateMetadata'));
const ProjectView = lazy(() => import('../../project/ProjectView'));
const AdminProjectView = lazy(() => import('../../project/general/AdminProjectView'));
const UploadStepper = lazy(() => import('../../damsfileupload/UploadStepper'));
const AdminLocalAssets = lazy(() => import('../../admin-local-assets/AdminLocalAssets'));
const FoldersList = lazy(() => import('../../folder/list/FoldersList'));
const AddOrReIndexSingle = lazy(() => import('../../admin/add-or-reindex/AddOrReIndexSingle'));
const CreateProjectPage = lazy(() => import('../../project/CreateProjectPage'));
const AllProjects = lazy(() => import('./AllProjects'));
const UserDocuments = lazy(() => import('../../search/UserDocuments'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SearchLandingPageRoute = () => {
    return (
        <SearchProvider>
            <SearchLandingPage/>
        </SearchProvider>
    );
};

export const MyFilesRoute = () => {
    return (
        <SearchProvider>
            <UserDocuments/>
        </SearchProvider>
    );
};

export const NewImagesRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"StillImage"}/>
        </NewFilesPage>
    );
};

export const NewVideosRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Video"}/>
        </NewFilesPage>
    );
};

export const NewDocumentsRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Misc"}/>
        </NewFilesPage>
    );
};

export const NewSoundsRoute = () => {
    return (
        <NewFilesPage>
            <FileForm documentType={"Audio"}/>
        </NewFilesPage>
    );
};

// Project-related routeds below this line
export const NewProjectRoute = () => {
    return (
        <MyFoldersProvider>
            <ProjectProvider>
                <CreateProjectPage/>
            </ProjectProvider>
        </MyFoldersProvider>
    );
};

export const ProjectRoute = () => {
    return (
        <ProjectViewProvider>
            <SearchProvider>
                <ProjectView/>
            </SearchProvider>
        </ProjectViewProvider>
    );
};

export const ProjectAdminRoute = () => {
    return (
        <FoldersProvider>
            <DocumentProvider>
                <ProjectViewProvider>
                    <AdminProjectView/>
                </ProjectViewProvider>
            </DocumentProvider>
        </FoldersProvider>
    );
};

/**
 * Component exposing the routes available to authenticated users or an external user.
 * External user: A user that has been given access via a shared folder,
 * without having been granted access to the DAMS application or a user without any association with a museum.
 * This user may be logged on via eKultur. If the user has not been given access via a shared folder, an
 * info dialog is shown, if granted access via a shared folder, the contents of the folder is shown.
 * @returns {JSX.Element}
 * @constructor
 */
export const RoutesAuthenticated = () => {
    const appDispatch = useAppDispatch();
    const {externalUser} = useAppState();
    const {userData, userIsAuthenticated} = useAuthsState();
    const {hasArcheologyModule} = useProjectState();

    useEffect(() => {
        if (!userData) {
            return;
        }

        let accessMuseumBehalf = false;
        if (userData.appAccess?.museums) {
            const museums = userData.appAccess.museums;
            for (let i = 0, max = museums.length; i < max; i++) {
                if (museums[i].applications.find(a => a.id === window._env_.REACT_APP_ID)) {
                    accessMuseumBehalf = true;
                    break;
                }
            }
        }
        appDispatch({type: SET_MUSEUM_USER_OR_ADMIN, value: accessMuseumBehalf});

        if (userData.appAccess.museums.length === 0) {
            appDispatch({type: SET_EXTERNAL_USER});
        }
    }, [userData]);

    if (!userIsAuthenticated) {
        return <></>;
    } else if (userIsAuthenticated) {
        return (
            <>
                <Suspense fallback={<></>}>
                    <SearchProvider>
                        <RestrictedAccessInformation/>
                    </SearchProvider>
                </Suspense>
                <SentryRoutes>
                    {/* Show the routes available to users that is granted access to the DAMS application */}
                    {!externalUser && (
                        <>
                            <Route exact path={"/"} element={<SearchLandingPageRoute/>}/>
                            <Route path={"/search"}>
                                <Route
                                    path={":searchString"}
                                    element={<SearchLandingPageRoute/>}
                                />
                                <Route path={"/search"} element={<SearchLandingPageRoute/>}/>
                            </Route>
                            <Route exact path={"/my-files/"}
                                   element={<Suspense fallback={<></>}><MyFilesRoute/></Suspense>}/>
                            <Route exact path={"/new-images/"}
                                   element={<Suspense fallback={<></>}><NewImagesRoute/></Suspense>}/>
                            <Route exact path={"/new-videos/"}
                                   element={<Suspense fallback={<></>}><NewVideosRoute/></Suspense>}/>
                            <Route exact path={"/upload"}
                                   element={<Suspense fallback={<></>}><DocumentProvider>
                                       <UploadStepper/>
                                   </DocumentProvider>
                                   </Suspense>}
                            />
                            <Route
                                exact
                                path={"/new-documents/"}
                                element={<Suspense fallback={<></>}><NewDocumentsRoute/></Suspense>}
                            />
                            <Route exact path={"/new-sounds"}
                                   element={<Suspense fallback={<></>}><NewSoundsRoute/></Suspense>}/>
                            {/* Folder routes */}
                            <Route exact path={"/my-folders/"} element={
                                <Suspense fallback={<></>}>
                                    <FoldersList
                                        folderType={"myfolders"}/>
                                </Suspense>}/>
                            <Route
                                exact
                                path={"/all-folders/"}
                                element={<Suspense fallback={<></>}><FoldersList/></Suspense>}
                            />
                            <Route
                                exact
                                path={"/shared-folders/"}
                                element={<Suspense fallback={<></>}><FoldersList
                                    folderType={"sharedfolders"}/></Suspense>}
                            />
                            {/* Admin routes */}
                            <Route exact path={"/restore"}
                                   element={<Suspense fallback={<></>}>
                                       <DocumentProvider>
                                           <RestoreDocuments/>
                                       </DocumentProvider>
                                   </Suspense>}/>
                            <Route exact path={"/reindex"}
                                   element={<Suspense fallback={<></>}><AdminReindex/></Suspense>}/>
                            <Route
                                exact
                                path={"/metadata-mapping"}
                                element={<Suspense fallback={<></>}><AdministrateMetadataMapping/></Suspense>}/>
                            />
                            <Route exact path={'/admin-local-assets'}
                                   element={<Suspense
                                       fallback={<></>}><DocumentProvider><AdminLocalAssets/></DocumentProvider></Suspense>}/>
                            <Route exact path={"/regeneratemetadata"}
                                   element={
                                       <Suspense fallback={<></>}>
                                           <DocumentProvider>
                                               <RegenerateMetadata/>
                                           </DocumentProvider>
                                       </Suspense>
                                   }/>
                            <Route exact path={"/add-to-index"}
                                   element={
                                       <Suspense fallback={<></>}>
                                           <DocumentProvider>
                                               <AddOrReIndexSingle/>
                                           </DocumentProvider>
                                       </Suspense>
                                   }/>
                            <Route exact path={"/import"}
                                   element={<Suspense fallback={<></>}><AdminImportData/></Suspense>}/>
                            <Route exact path={"/stats"} element={<Suspense fallback={<></>}><DAMSStats/></Suspense>}/>
                            {/* Project routes */}
                            {hasArcheologyModule && (<>
                                <Route
                                    path={"/new-project/:projectType"}
                                    element={<Suspense fallback={<></>}><NewProjectRoute/></Suspense>}
                                />
                                <Route
                                    exact
                                    path={"/project/:projectId"}
                                    element={<Suspense fallback={<></>}><ProjectRoute/></Suspense>}
                                />
                                <Route
                                    exact
                                    path={"/project/admin/:projectId"}
                                    element={<Suspense fallback={<></>}><ProjectAdminRoute/></Suspense>}
                                />
                                <Route
                                    exact
                                    path={"/all-projects"}
                                    element={<Suspense fallback={<></>}><AllProjects/></Suspense>}
                                />
                            </>)}
                        </>
                    )}
                    {/* A single folder, must be accessible for all users, incl. external users */}
                    <Route
                        exact
                        path={"/folders/:folderId/"}
                        element={<Suspense fallback={<></>}><FolderByIdRoute/></Suspense>}
                    />
                </SentryRoutes>
            </>
        );
    }
};
