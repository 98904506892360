import React from "react";
import {Formik} from "formik";
import {FormikTextField} from "../form/FormikTextField";
import {FormActions} from "../metadata/FormActions";
import {useDocumentTranslation} from "../documents/documentContext";
import * as Yup from "yup";
import {FormikSelect} from "../form/FormikSelect";
import {useKulturNavPlaceTypes} from "../kulturnav/useKulturNavPlaceTypes";
import {useAddPlace} from "./useAddPlace";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

const schema = (t) => {
    return Yup.object().shape({
        name: Yup.string().required(t("requiredFeedback", "Påkrevd")),
        placeType: Yup.string().required(t("requiredFeedback", "Påkrevd")),
    });
};

export const FormNewPlace = ({
                                 place,
                                 onClose,
                                 collectionIds,
                                 addHandler = null,
                             }) => {
    const t = useDocumentTranslation();
    const placeTypes = useKulturNavPlaceTypes({});
    const postPlaces = useAddPlace();

    const onSubmit = (values) => {
        const newPlaces = collectionIds.map((collectionId) => ({
            ...values,
            collectionId: collectionId,
        }));
        postPlaces({
            places: newPlaces,
        }).then((np) => {
            if (typeof addHandler !== 'undefined') {
                addHandler(np);
            }
            onClose();
        });
    };

    return (
        <Formik
            initialValues={{
                name: place.title,
                placeType: "",
            }}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={schema(t)}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FormikTextField
                    formikKey={"name"}
                    label={t("placeName", "Navn")}
                    required={true}
                />
                <Box sx={{marginBottom: '8px'}}/>
                <FormikSelect
                    formikKey={"placeType"}
                    label={t("placeType", "Stedstype")}
                >
                    {placeTypes.map((placeType) => (
                        <MenuItem value={placeType} key={placeType}>{placeType}</MenuItem>
                    ))}
                </FormikSelect>
                <FormActions onCancel={onClose}/>
            </Box>
        </Formik>
    );
};
