import React, {useEffect, useState} from "react";
import {
    ADD_FQ,
    CLEAR_FILTERS,
    CLEAR_FILTERS_DONE,
    CLEAR_FQ,
    NOT_PROJECT_CONTEXT,
    PROJECT_CONTEXT,
    REMOVE_FQ,
    SET_SEARCHING,
    SET_START,
    SHOW_FILTERS,
    UPDATE_FQ,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "../SearchContext";
import {useIsAdminOfMuseum} from "../../auths/useIsAdminOfMuseum";
import {useMuseumState} from "../../museum/MuseumContext";
import {useTheme} from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useLocation} from "react-router-dom";
import {FilterProjectSubType} from "./FilterProjectSubType";
import {FilterProjectState} from "./FilterProjectState";
import {FilterSubjects} from "./FilterSubjects";
import {FilterDuration} from "./FilterDuration";
import {AccordionFilterRights} from "./AccordionFilterRights";
import {FilterStatus} from "./FilterStatus";
import {FilterProjectType} from "./FilterProjectType";
import {FilterDocumentTypes} from "./FilterDocumentTypes";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import {AccordionFilterDateNew} from "./AccordionFilterDateNew";
import {FilterProducer} from "./FilterProducer";
import {FilterPersons} from "./FilterPersons";
import {FilterPlace} from "./FilterPlace";
import {FilterCreator} from "./FilterCreator";
import {FilterLanguages} from "./FilterLanguages";
import {FilterFileTypes} from "./FilterFileTypes";
import {FilterReferenceType} from "./FilterReferenceType";

/**
 * FilterSearch component is responsible for rendering and managing search filters for projects.
 * It allows users to apply various filters to search results and provides options to clear filters.
 *
 * Props:
 * - projectId: The ID of the project for which the filters are being applied.
 *
 * State Management:
 * - Uses context from SearchContext and MuseumContext to manage search state and user roles.
 *
 * Functionality:
 * - Displays filters related to project types, subjects, dates, and more.
 * - Handles applying, clearing, and managing the state of filters.
 * - Supports project-specific and global filtering logic.
 *
 * Conditional Rendering:
 * - Renders filter UI components based on user role and current search context.
 *
 * Effects:
 * - Updates filter state based on project ID and search results.
 * - Ensures filters are applied correctly in different contexts (project vs. non-project).
 */
export const FilterSearch = ({projectId}) => {
    const theme = useTheme();
    const t = useSearchTranslation();
    const searchDispatch = useSearchDispatch();

    // eslint-disable-next-line no-unused-vars
    const {searching, filtersHidden, onlyMine, results, fq, clearFilters} = useSearchState(); // NOSONAR
    const {museums} = useMuseumState();
    const isMuseumAdmin = useIsAdminOfMuseum(museums);
    const [clearFilter, setClearFilter] = useState(false);
    const [selected, setSelected] = useState([]);
    const [minMaxDates, setMinMaxDates] = useState();
    const [loading, setLoading] = useState();
    const [shouldRender, setShouldRender] = useState();
    const [documentTypesAltered, setDocumentTypesAltered] = useState(false);

    const location = useLocation();
    const isProjectListing = location.pathname === '/all-projects/';

    /**
     * Clears all filters and sets the search to loading again.
     * This also resets the altered state of document types.
     * The state is set to loading and the filters are cleared.
     * After a delay of 500ms, the state is set back to not loading,
     * the filters cleared message is set, and the search is set to loading again.
     */
    const clearFiltersHandler = () => {
        setSelected([]);
        setClearFilter(true);
        setDocumentTypesAltered(false);
        searchDispatch({type: CLEAR_FILTERS});
        setTimeout(() => {
            setClearFilter(false);
            searchDispatch({type: CLEAR_FILTERS_DONE});
            searchDispatch({type: SET_SEARCHING});
        }, 500);
    };

    /**
     * Finds the value of the "folder_ids" filter in the current search filters array.
     * If the filter is found, its value is returned. Otherwise, undefined is returned.
     * @returns {string|undefined} The value of the "folder_ids" filter or undefined if not found.
     */
    const getFolderIds = () => {
        let folderIds = undefined;
        const match = fq.find((f) => f.key === "folder_ids");
        if (match) {
            folderIds = match.value;
        }
        return folderIds;
    };

    /**
     * Applies the selected filters and executes a search.
     *
     * Clears the current filters and sets the start to 0.
     * If the "folder_ids" filter is set, it is added to the filters array.
     * If the selected array is not empty, it iterates over the selected filters and adds them to the filters array.
     * If in project-context, it appends the project ID filter.
     * If searching among the available projects, it removes the "folder_ids" argument.
     * Finally, it sets the searching state to true and executes a search.
     */
    const applyFilters = () => {
        const folderIds = getFolderIds();

        searchDispatch({
            type: CLEAR_FQ,
        });

        searchDispatch({
            type: SET_START,
            start: 0,
        });

        if (folderIds) {
            searchDispatch({
                type: ADD_FQ,
                fq: {key: "folder_ids", value: folderIds},
            });
        }

        if (selected.length > 0) {
            // Execute search with set filters.
            for (let i = 0, max = selected.length; i < max; i++) {
                const f = selected[i];
                if (f.value && (f.value.to || f.value.from)) {
                    // Handle date periods
                    searchDispatch({
                        type: ADD_FQ,
                        fq: {
                            key: f.key,
                            value: `[${f.value.from} TO ${f.value.to}]`,
                        },
                    });
                } else if (f.key) {
                    // Handle "plain" values
                    searchDispatch({
                        type: ADD_FQ,
                        fq: {
                            key: f.key,
                            value: `"${f.value}"`,
                        },
                    });
                }
            }

            // If in project-context, append project ID filter
            if (projectId) {
                searchDispatch({
                    type: UPDATE_FQ,
                    fq: {
                        key: "folder_ids",
                        value: projectId,
                    },
                });
            }

            // If searching among the available projects, remove the "folder_ids" argument, as this interfere with
            // the "archeologyprojectref.type" filter.
            const hasProjecTypeRef = selected.find(s => s.key === 'archeologyprojectref.type');
            if (hasProjecTypeRef) {
                searchDispatch({
                    type: REMOVE_FQ,
                    fq: {
                        key: 'folder_ids',
                        value: folderIds
                    }
                });
            }
        } else {
            // All filters removed, clear filters and execute search.
            searchDispatch({
                type: CLEAR_FILTERS,
            });
            setTimeout(() => {
                searchDispatch({
                    type: CLEAR_FILTERS_DONE
                });
            }, 500);
        }

        // Executes search!
        searchDispatch({type: SET_SEARCHING});
    };

    /**
     * Updates the selected filters with provided filters for a specific subject key.
     *
     * @param {string} subjectsKey - The key for which the filters are being updated.
     * @param {Array} filters - An array of filters to be set for the specified key.
     *
     * This function replaces the existing filters that match the provided subject key
     * with the new filters. If there are no existing filters, it sets the new filters directly.
     * Additionally, if filters were being cleared before this update, it clears all filters
     * and dispatches a CLEAR_FILTERS_DONE action after a short delay.
     */
    const setFiltersCallback = (subjectsKey, filters) => {
        const wasClearing = clearFilter;
        setClearFilter(false);

        // Replace filters matching the specified subjectsKey
        if (selected.length > 0) {
            const arr = selected.filter((f) => f.key !== subjectsKey).concat(filters);
            setSelected(arr);
        } else {
            setSelected(filters);
        }

        if (wasClearing) {
            searchDispatch({type: CLEAR_FILTERS});
            setTimeout(() => {
                searchDispatch({type: CLEAR_FILTERS_DONE});
            }, 20);
        }
    };

    /**
     * Renders a Box with multiple filters, which can be used to filter search results.
     * The filters are organized into several sections, such as project related filters,
     * and file related filters. The filters are rendered as components from the
     * `filters` folder, and are passed a callback function which is called when the filter
     * values change. The callback function updates the selected filters in the
     * SearchContext.
     *
     * @return {JSX.Element} A Box containing the filters.
     */
    const getFilters = () => {
        return (
            <Box sx={{
                overflowY: "scroll",
                overflowX: 'hidden',
                flexGrow: 1,
                scrollbarColor: '#009688 #ddd',
                scrollbarWidth: 'thin'
            }}>
                {!isProjectListing && (
                    <Box sx={{marginTop: '8px', marginBottom: '8px', paddingInline: '1rem'}}>
                        <FilterDocumentTypes disableProjectsOption={Boolean(projectId)}
                                             callback={() => setDocumentTypesAltered(true)}/>
                    </Box>
                )}

                {/* Project related filters */}
                <FilterProjectType callback={setFiltersCallback}/>
                <FilterProjectSubType callback={setFiltersCallback} projectTypeKey={'archeologyprojectref.type'}/>
                <FilterProjectSubType callback={setFiltersCallback} projectTypeKey={'generalprojectref.type'}/>
                {!projectId && isProjectListing && <FilterProjectState callback={setFiltersCallback}/>}
                {/* Project related filters */}

                <FilterProducer callback={setFiltersCallback}/>
                <FilterPersons callback={setFiltersCallback}/>
                <FilterPlace callback={setFiltersCallback}/>
                <FilterSubjects callback={setFiltersCallback}/>

                {(!onlyMine) &&
                    <FilterCreator callback={setFiltersCallback}/>
                }

                <AccordionFilterDateNew
                    key={'updatedFilter'}
                    accordionTitle={t("updatedAt", "Oppdatert dato")}
                    callback={setFiltersCallback}
                    columnName={"updated_at"}
                />

                <AccordionFilterDateNew
                    key={'createdFilter'}
                    accordionTitle={t("createdAt", "Opprettet dato")}
                    callback={setFiltersCallback}
                    columnName={"created_at"}
                />


                {!isProjectListing && (
                    <AccordionFilterDateNew
                        key={'productionFilter'}
                        accordionTitle={t("productionDate", "Produksjonsdato")}
                        callback={setFiltersCallback}
                        columnName={"produced_at"}
                    />
                )}

                <FilterLanguages callback={setFiltersCallback}/>

                <FilterFileTypes callback={setFiltersCallback}/>

                {results.facets.durationSeconds && (
                    <FilterDuration
                        results={results}
                        callback={setFiltersCallback}
                        clearFilters={clearFilter}
                    />
                )}

                {!isProjectListing &&
                    <AccordionFilterRights key={'filterRights'}
                                           callback={setFiltersCallback}/>
                }

                {!isProjectListing && <FilterReferenceType callback={setFiltersCallback}/>}

                {isMuseumAdmin && <FilterStatus/>}
            </Box>
        );
    };

    /**
     * Hook used to clear the filters when the component is mounted, e.g. on the initial render
     * or when changing to another context such as projects, files or folders, and determining if we're in a
     * project context or not.
     */

    useEffect(() => {
        clearFiltersHandler();
        if (projectId) {
            searchDispatch({type: PROJECT_CONTEXT});
        } else {
            searchDispatch({type: NOT_PROJECT_CONTEXT});
        }
    }, []);

    useDeepCompareEffect(() => {
        // Get "global" min/max dates, used to create the min/max date-intervals
        // for the production-, created- and updated date filters.
        const stats = results.stats;
        if (
            minMaxDates ||
            (Object.values(stats.productionDate).length === 0 &&
                Object.values(stats.createdAt).length === 0 &&
                Object.values(stats.updatedAt).length === 0)
        ) {
            return;
        }
        setMinMaxDates(stats);
        setLoading(false);
    }, [results.stats, minMaxDates]);

    useEffect(() => {
        if ((searching || loading) && (minMaxDates === undefined || minMaxDates === null)) {
            return;
        }
        setShouldRender(true);
    }, [loading, searching, minMaxDates]);

    /**
     * Hook used to clear all filter values, when the user has clicked "Nullstill" after a search
     * yielded no results.
     */
    useEffect(() => {
        if (!clearFilters || searching) {
            return;
        }
        clearFiltersHandler();
    }, [clearFilters]);

    /**
     * Function that determines whether the "Clear filters" button should be disabled.
     *
     * The "Clear filters" button should be disabled if no filters are selected and no
     * document types have been altered.
     *
     * @returns {boolean} True if the "Clear filters" button should be disabled, false otherwise.
     */
    const shouldDisableClearFilterButton = () => {
        return selected.length === 0 && documentTypesAltered !== true;
    }

    if (shouldRender && filtersHidden) {
        return <IconButton
            onClick={() => searchDispatch({type: SHOW_FILTERS})}
            size="large"
        >
            <FilterListIcon/>
        </IconButton>
    } else if (shouldRender && !filtersHidden) {
        return (
            <Box id="filterFlexWrapper" sx={{display: 'flex', flexDirection: 'column', minWidth: '100%'}}>
                <Toolbar sx={{
                    "&.MuiToolbar-root": {
                        paddingLeft: 2,
                        paddingRight: 2,
                    }
                }}>
                    <Typography variant="h5" component="h2">
                        {t("filter", "Filter")}
                    </Typography>

                    <Box flexGrow={1}/>

                    <Button
                        sx={{
                            marginRight: theme.spacing(1),
                        }}
                        disabled={shouldDisableClearFilterButton()}
                        color={"secondary"}
                        size={"small"}
                        onClick={() => clearFiltersHandler()}
                        startIcon={<ClearAllIcon/>}
                    >
                        {t("clearFilter", "Tøm Filter")}
                    </Button>

                    <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        onClick={() => applyFilters()}
                        startIcon={<FilterListIcon/>}
                    >
                        {t("applyFilter", "Filtrer")}
                    </Button>

                </Toolbar>
                <Divider orientation={"horizontal"}/>
                {getFilters()}
            </Box>
        );
    }
};
