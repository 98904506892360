import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {decamelize} from "../app/decamelize";

export const SET_QUERY = "searchContext/setQuery";
export const ADD_FQ = "searchContext/addFq";
export const CLEAR_SEARCH_AND_ADD_FQ = "searchContext/clearSearchAndAddFq";
export const UPDATE_FQ = "searchContext/updateFq";
export const REMOVE_FQ = "searchContext/removeFq";
export const CLEAR_FQ = "searchContext/clearFq";
export const SET_SORT = "searchContext/setSearch";
export const SET_ROWS = "searchContext/setRows";
export const SET_START = "searchContext/setStart";
export const SET_SEARCHING = "searchContext/setSearching";
export const SET_ERROR = "searchContext/setError";
export const SET_RESULT = "searchContext/setResult";
export const CLEAR_FILTERS = "searchContext/clearFilters";
export const CLEAR_FILTERS_DONE = "searchContext/clearFilterDone";
export const SHOW_FILTERS = "searchContext/showFilters";
export const HIDE_FILTERS = "searchContext/hideFilters";
export const CLEAR_RESULTS = "searchContext/clearResults";
export const SET_SELECTED = "searchContext/setSelected";
export const SELECT = "searchContext/select";
export const SELECT_MODELS = "searchContext/selectModels";
export const SELECT_ALL = "searchContext/selectAll";
export const UNSELECT = "searchContext/unselect";
export const UNSELECT_ALL = "searchContext/unselectAll";
export const MODELS_SAVED = "searchContext/modelsSaved";
export const SEARCH_ONLY_MINE = "searchContext/searchOnlyMine";
export const PROJECT_CONTEXT = "searchContext/projectContext";
export const NOT_PROJECT_CONTEXT = "searchContext/notProjectContext";

export const SHOW_INSTANTLY = "searchContext/showInstantly";

export const SET_DELETING = 'searchContext/setDeleting';
export const CLEAR_DELETING = 'searchContext/clearDeleting';


const SearchStateContext = createContext(undefined);
const SearchDispatchContext = createContext(undefined);
const SearchTranslationContext = createContext(undefined);

const initialState = {
    query: "",
    timestamp: undefined,
    folderContext: false,
    sort: "created_at desc",
    fl: null,
    start: 0,
    rows: 10,
    expand: true,
    facetField:
        "persons.reference.title,producer.reference.title,places.reference.title,licenses.reference.title," +
        "subjects.reference.title,created_by_name,languages.title," +
        "document_type," +
        "mediae.reference.mime_type,mediae.reference.duration_seconds,archeologyprojectref.type," +
        "generalprojectref.type,archeologyprojectref.active,generalprojectref.active," +
        "copyright_type,copyright_info.title,reference_types.ref_type.title",
    statsField:
        "created_at,updated_at,production_date,mediae.reference.duration_seconds",
    fq: [],
    projectContext: false,
    results: {
        count: -1,
        facets: {
            persons: {},
            producers: {},
            places: {},
            licenses: {},
            subjects: {},
            creators: {},
            languages: {},
            mimeTypes: {},
            durationSeconds: {},
            projectTypes: {},
            projectStates: {},
            documentTypes: {},
            copyrightTypes: {},
            copyrightClauses: {}
        },
        stats: {
            createdAt: {},
            updatedAt: {},
            productionDate: {},
        },
        models: [],
        error: false,
        modelsOrder: {},
    },
    searching: false,
    filtersHidden: false,
    selected: [],
    triggerSearch: 0,
    onlyMine: false,
    deleting: false,
    clearFilters: false,
    showInstantly: null
};

const updateLatestChanges = (old, updated) => {
    return [
        ...updated.filter((model) =>
            old.map((o) => o.uniqueId).includes(model.uniqueId)
        ),
        ...old.filter((o) => !updated.map((u) => u.uniqueId).includes(o.uniqueId)),
    ];
};

const searchReducer = (state, action) => {
    switch (action.type) {
        case SHOW_INSTANTLY:
            return {
                ...state,
                showInstantly: action.showInstantly
            };
        case SET_QUERY:
            return {
                ...state,
                query: action.query,
                timestamp: action?.timestamp || undefined,
                folderContext: action.folderContext,
                results: {
                    ...state.results,
                    count: -1
                },
                // searching: true
            };
        case SET_SORT:
            return {
                ...state,
                sort: decamelize(action.sort),
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case SET_ROWS:
            return {
                ...state,
                start: 0,
                rows: action.rows,
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case SET_START:
            return {
                ...state,
                start: action.start,
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case ADD_FQ:
            return {
                ...state,
                fq: [...state.fq, action.fq],
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case CLEAR_SEARCH_AND_ADD_FQ:
            return {
                ...state,
                query: "*",
                onlyMine: state.onlyMine,
                fq: [action.fq],
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case UPDATE_FQ:
            return {
                ...state,
                fq: [
                    ...state.fq.filter((singleFq) => singleFq.key !== action.fq.key),
                    action.fq,
                ],
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case REMOVE_FQ:
            return {
                ...state,
                fq: state.fq.filter(
                    (field) =>
                        !(field.key === action.fq.key && field.value === action.fq.value)
                ),
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case CLEAR_FQ:
            return {
                ...state,
                fq: [],
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case SET_SEARCHING:
            return {
                ...state,
                searching: true,
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case SET_RESULT:
            return {
                ...state,
                results: {
                    count: action.count,
                    facets: action.facets,
                    models: action.models,
                    stats: action.stats,
                    error: false,
                    modelsOrder: action.models
                        .filter((f) => f.status !== "project")
                        .reduce(
                            (acc, cur, idx) => ({
                                ...acc,
                                [idx]: cur.uniqueId,
                            }),
                            {}
                        ),
                },
                searching: false,
            };
        case CLEAR_FILTERS:
            if (!state.projectContext) {
                return {
                    ...state,
                    query: '*',
                    fq: state.fq.filter(
                        (field) =>
                            "folders.reference.title" === field.key ||
                            "folder_ids" === field.key
                    ),
                    start: 0,
                    clearFilters: true,
                    results: {
                        ...state.results,
                        count: -1
                    }
                };
            } else if (state.projectContext) {
                let fq = state.fq.filter(
                    (field) =>
                        "folders.reference.title" === field.key ||
                        "folder_ids" === field.key
                );
                return {
                    ...state,
                    query: '*',
                    fq: fq,
                    start: 0,
                    clearFilters: true,
                    results: {
                        ...state.results,
                        count: -1
                    }
                };
            }
            break;
        case CLEAR_FILTERS_DONE:
            return {
                ...state,
                clearFilters: false,
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case SET_ERROR:
            return {
                ...state,
                searching: false,
                results: {
                    ...initialState.results,
                    error: true,
                },
            };
        case SHOW_FILTERS:
            return {
                ...state,
                filtersHidden: false,
            };
        case HIDE_FILTERS:
            return {
                ...state,
                filtersHidden: true,
            };
        case CLEAR_RESULTS:
            return {
                ...state,
                results: initialState.results,
            };
        case SET_SELECTED:
            return {
                ...state,
                selected: action.selected,
            };
        case SELECT:
            return {
                ...state,
                selected: [...state.selected, action.model],
            };
        case SELECT_MODELS:
            return {
                ...state,
                selected: [...action.models],
            };
        case SELECT_ALL:
            return {
                ...state,
                selected: state.results.models,
            };
        case UNSELECT:
            return {
                ...state,
                selected: state.selected.filter(
                    (model) => model.uniqueId !== action.model.uniqueId
                ),
            };
        case UNSELECT_ALL:
            return {
                ...state,
                selected: [],
            };
        case MODELS_SAVED:
            return {
                ...state,
                selected: updateLatestChanges(state.selected, action.models),
                triggerSearch: state.triggerSearch + 1,
            };
        case SEARCH_ONLY_MINE:
            return {
                ...state,
                onlyMine: true,
                query: "*",
                results: {
                    ...state.results,
                    count: -1
                }
            };
        case PROJECT_CONTEXT:
            return {
                ...state,
                projectContext: true,
            };
        case NOT_PROJECT_CONTEXT:
            return {
                ...state,
                projectContext: false,
            };
        case SET_DELETING:
            return {
                ...state,
                deleting: true
            };
        case CLEAR_DELETING:
            return {
                ...state,
                deleting: false
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const SearchProvider = ({children}) => {
    const [state, dispatch] = useReducer(searchReducer, initialState, undefined);
    const {t, ready} = useTranslation("dams.search", {useSuspense: false});
    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <SearchTranslationContext.Provider value={t}>
            <SearchStateContext.Provider value={state}>
                <SearchDispatchContext.Provider value={dispatch}>
                    {children}
                </SearchDispatchContext.Provider>
            </SearchStateContext.Provider>
        </SearchTranslationContext.Provider>
    );
};

export const useSearchState = () => {
    const context = useContext(SearchStateContext);
    if (undefined === context) {
        throw new Error("useSearchState must be used within a SearchProvider");
    } else {
        return context;
    }
};

export const useSearchDispatch = () => {
    const context = useContext(SearchDispatchContext);
    if (undefined === context) {
        throw new Error("useSearchDispatch must be used withing a SearchProvider");
    } else {
        return context;
    }
};

export const useSearchTranslation = () => {
    const context = useContext(SearchTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useSearchTranslation must be used within an SearchProvider."
        );
    } else {
        return context;
    }
};
